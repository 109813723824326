import React from 'react'
import { Link } from 'gatsby'
import Phone from './Phone'
import LargeCTAStyles from '../../styles/LargeCTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string} title
 * @param {string} text
 * @param {string} buttonText Button label
 */
const LargeCTA = props => {
  return (
    <div className={`large-cta ${LargeCTAStyles.cta}`}>
      <p className={`title is-4 ${LargeCTAStyles.title}`}>
        {props.title || 'Need an Immersion Heater Repair?'}
      </p>
      <p className={LargeCTAStyles.text}>
        {props.text ||
          'We are ready to diagnose and fix all your heating issues. Get in touch with us today for a callout.'}
      </p>
      <div className={`buttons are-large ${LargeCTAStyles.buttons}`}>
        <Link to="/contact/" className="button is-link">
          {props.buttonText || 'Get a Callout'}
        </Link>
        <Phone class="button is-link is-inverted phone" />
      </div>
    </div>
  )
}

export default LargeCTA

/**
 * Create Netlify CMS Editor Component
 */
const LargeCTAEditorComponent = {
  // Internal id of the component
  id: 'large-cta',
  // Visible label
  label: 'Large CTA',
  // Fields the user need to fill out when adding an instance of the component
  fields: [
    {
      name: 'title',
      label: 'Title',
      widget: 'string',
      default: 'Need an Immersion Heater Repair?',
    },
    {
      name: 'text',
      label: 'Text',
      widget: 'string',
      default:
        'We are ready to diagnose and fix all your heating issues. Get in touch with us today for a callout.',
    },
    {
      name: 'buttonText',
      label: 'Button Text',
      widget: 'string',
      default: 'Get a Callout',
    },
  ],
  // Pattern to identify a block as being an instance of this component
  pattern: /^<LargeCTA title="(.*)" text="(.*)" buttonText="(.*)" \/>/,
  // Function to extract data elements from the regexp match
  fromBlock: function(match) {
    return {
      title: match[1],
      text: match[2],
      buttonText: match[3],
    }
  },
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview: function(obj) {
    return (
      <LargeCTA title={obj.title} text={obj.text} buttonText={obj.buttonText} />
    )
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return (
      '<LargeCTA title="' +
      obj.title +
      '" text="' +
      obj.text +
      '" buttonText="' +
      obj.buttonText +
      '" />'
    )
  },
}

export { LargeCTAEditorComponent }
