import React from 'react'
import { Link } from 'gatsby'
import SiteMetadata from './SiteMetadata'
import Button from './shortcodes/Button'
import ContactForm from './ContactForm'
import Map from './Map'
import LeinsterGroupLogo from '../../static/media/leinster-group-logo.svg'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 *
 * @param {boolean} showContactForm Default: true
 */
const Footer = props => {
  const { title } = SiteMetadata()

  return (
    <>
      {props.showContactForm !== 'false' && (
        <div
          className={`is-clearfix contact-form-holder ${FooterStyles.contactFormHolder}`}
        >
          <div
            className={`container is-semi-narrow ${FooterStyles.contactFormContainer}`}
          >
            <ContactForm className={FooterStyles.form} buttonClass="is-link" />
            <div className={FooterStyles.mapHolder}>
              <Map className={FooterStyles.map} />
              <div className={FooterStyles.directions}>
                <strong>Address:</strong>
                <p>Unit 5C, Kylemore Park North, Dublin 10 — D10 TX39</p>
                <Button
                  url="https://goo.gl/maps/9nKsASw1V34xio5g6"
                  external="true"
                  text="View on Map"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <footer className={`footer ${FooterStyles.footer}`}>
        <div className="container has-text-centered is-semi-narrow">
          <a
            className={FooterStyles.owner}
            href="https://leinstergroup.ie/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LeinsterGroupLogo}
              alt="Leinster Group"
              style={{
                width: '110px',
                height: '44px',
              }}
            />
          </a>
          <p>
            Copyright{' '}
            <span className="current-year">{new Date().getFullYear()}</span>{' '}
            {title}. All rights reserved | <Link to="/blog">Blog</Link>{' '}
            <span className={FooterStyles.shamrock}>From Dublin with Love</span>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
